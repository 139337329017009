<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.maintenanceContractManagement')"
    id="maintenanceContractManagement"
  >
    <div id="outer-title">
      {{ $t("i18n.maintenanceContractManagement") }}
    </div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
// import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "UpkeepContractView",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    // const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            enterEditPage(row);
          },
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          align: "left",
          width: 240,
          search: true,
          type: "input",
        },
        {
          prop: "remainDays",
          label: "contractRemainingDays",
          align: "center",
        },
        {
          prop: "createdDate",
          label: "createDate",
          align: "center",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          rows: false,
          search: true,
          type: "input",
        },
        {
          prop: "renewS",
          label: "renewalStatus",
          align: "center",
          search: true,
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          uploadUrl: "renew_status",
        },
        {
          prop: "upkeepType",
          label: "contractType",
          rows: false,
          search: true,
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "upkeep_type",
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
        },
        {
          prop: "remainElevator",
          label: "numberOfElevator",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "generateBatch",
            icon: "el-icon-plus",
            show: proxy.$defined.btnPermission("生成批次"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              enterAdd(row.id);
            },
          },
          {
            id: "2",
            label: "viewScheduler",
            icon: "el-icon-s-claim",
            class: "orange",
            show: proxy.$defined.btnPermission("查看保养日程"),
            type: "outer",
            method: () => {
              let routeData = router.resolve({
                path: "/upkeepContract/upkeepContractViewschedule",
              });
              window.open(routeData.href, "_blank");
            },
          },
          {
            id: "3",
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加保养合同"),
            type: "outer",
            method: () => {
              router.push({
                path: "/upkeepContract/upkeepContractViewadd",
              });
            },
          },
          {
            id: "4",
            label: "import",
            icon: "el-icon-download",
            class: "import",
            show: true,
            type: "outer",
            method: () => {
              router.push({
                path: "/upkeepContract/dataIn",
              });
            },
          },
          {
            id: "5",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: proxy.$defined.btnPermission("导出合同"),
            type: "outer",
            method: (formInline) => {
              contractExport(formInline);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (d) => {
      state.loading = true;
      let params = JSON.parse(JSON.stringify(d));
      params.renewStatus = params.renewS;
      delete params.renewS;
      params.descs = "created_date";
      const { data } = await proxy.$api.maintenance.contractPages(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const enterEditPage = (row) => {
      let page = row.upkeepType == 10 ? "Viewupdate" : "Viewcou";
      router.push({
        path: "/upkeepContract/upkeepContract" + page,
        query: { id: row.id },
      });
    };

    const enterAdd = (id) => {
      router.push({
        path: "/upkeepContract/planBathAdd",
        query: { id: id },
      });
    };

    const contractExport = async (formInline) => {
      state.operates.list[4].loading = true;
      let res = await proxy.$api.maintenance.contractExport(formInline);
      proxy.$defined.exportFunc(res);
      state.operates.list[4].loading = false;
    };

    return {
      ...toRefs(state),
      enterEditPage,
      init,
      enterAdd,
      contractExport,
    };
  },
};
</script>
